<template>
  <v-content>
    <v-container fluid grid-list-lg>
      <v-layout row wrap>
        <v-flex xs12 lg12 xl12>
          <app-title>
            <template slot="title">
              Hay
              <span class="font-weight-bold">{{ orders.length }}</span>
              pedido(s) sin ubicación
            </template>
            <template slot="subtitle">
              Solo aparecen los pedidos sin ubicacion del dia {{ general.date }}
            </template>
          </app-title>
        </v-flex>
        <template v-if="initMap && orders.length > 0">
          <v-flex v-for="(order, index) in orders" :key="index" xs12 sm6 md4>
            <v-card class="elevation-2">
              <h4 class="title font-weight-regular px-3 py-3">
                {{ `Pedido` }} <a :href="`/pedido/${order.id}/`" target="_blank">{{ order.id }}</a>
              </h4>
              <v-divider></v-divider>
              <template v-if="checkSend(index) === 0">
                <v-card-text class="pa-0">
                  <div class="px-3 py-3">
                    <GmapAutocomplete
                      placeholder="Ubicación"
                      class="input-search ma-0"
                      style="width: 100%"
                      :value="order.address"
                      @place_changed="setPlace(order, $event)"
                    ></GmapAutocomplete>
                    <p class="grey--text text--darken-1 ma-0 mt-2" v-html="`Dept, oficina, etc.: ${order.more}`"></p>
                  </div>
                  <gmap-map
                    :id="`map${order.id}`"
                    :ref="`mapRef${order.id}`"
                    :center="order.center"
                    :zoom="zoom"
                    :options="options"
                    :style="`width:100%;height:${windowHeight}px;`"
                  >
                    <GmapMarker :position="order.latLngOrder" :clickable="false" :draggable="true" />
                  </gmap-map>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn flat color="accent" @click="sendLocation(order, index)"> Listo </v-btn>
                </v-card-actions>
              </template>
              <template v-else-if="checkSend(index) === 1">
                <v-card-text class="text-xs-center">
                  <v-progress-circular :size="50" color="secondary" indeterminate></v-progress-circular>
                </v-card-text>
              </template>
              <template v-else-if="checkSend(index) === 2">
                <v-card-text class="text-xs-center">
                  <div>
                    <v-icon color="success" :size="40"> check_circle </v-icon>
                    <h4 class="title font-weight-regular grey--text mt-2">Enviado</h4>
                  </div>
                </v-card-text>
              </template>
            </v-card>
          </v-flex>
        </template>
        <template v-else>
          <v-flex xs12 class="text-xs-center"> No hay pedidos para geolocalizar </v-flex>
        </template>
      </v-layout>
    </v-container>
    <v-btn fixed dark fab bottom right color="accent" @click="getOrdersDocument">
      <v-icon>refresh</v-icon>
    </v-btn>
  </v-content>
</template>

<script>
import appTitle from './useful/title'
import appTitleCard from './useful/titleCard.vue'
import Status from './useful/status.vue'
import { GET_API, ORDER, GET_GEOLOCATION } from '../config'

export default {
  name: 'DocumentGeolocalization',
  components: { appTitle, Status, appTitleCard },
  metaInfo: {
    title: 'Pedidos sin ubicacion'
  },
  data: () => ({
    detail: {},
    latLngOrder: { lat: -33.4262, lng: -70.6429 },
    center: { lat: -33.4262, lng: -70.6429 },
    place: {},
    zoom: 16,
    options: {
      styles: [],
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false,
      disableDefaultUi: false
    },
    initMap: false,
    orders: []
  }),
  computed: {
    windowHeight() {
      if (window.innerHeight <= 1024 && window.innerHeight >= 1000) {
        return window.innerHeight - 800
      }
      if (window.innerHeight <= 1080 && window.innerHeight >= 1024) {
        return window.innerHeight - 700
      }
      if (window.innerHeight < 1000 && window.innerHeight >= 900) {
        return window.innerHeight - 600
      }
      if (window.innerHeight < 812) {
        return window.innerHeight - 500
      }
      return window.innerHeight - 200
    },
    general() {
      return this.$store.getters.getGeneral
    },
    massive() {
      return this.$store.getters.getMassive
    },
    addressComplete() {
      return ``
    }
  },
  watch: {
    'general.date': function(e) {
      this.getOrdersDocument()
    }
  },
  mounted() {
    this.getOrdersDocument()
  },
  methods: {
    async getOrdersDocument() {
      const res = await this.$http.get(GET_GEOLOCATION, {
        params: {
          p: 'getOrdersGeolocalization',
          date: this.general.date
        }
      })
      this.orders = res.data
      this.initMap = true
    },
    async sendLocation(order, index) {
      if (order.latLngOrder.lat !== this.center.lat && order.latLngOrder.lng !== this.center.lng) {
        order.send = 1
        try {
          this.loading = true
          await this.$http.post(`${ORDER}/${order.id}/location`, {
            latlng: order.latLngOrder,
            notify: 1
          })
          order.send = 2
        } catch (error) {
          this.$store.dispatch('changeSnack', {
            active: true,
            text: 'Ocurrio un error, intente nuevamente'
          })
        }
      } else {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Debe buscar la ubicaicion'
        })
      }
    },
    checkSend(index) {
      return this.orders[index].send
    },
    setLatLngOrder(position) {},
    setPlace(order, e) {
      const position = {
        lat: e.geometry.location.lat(),
        lng: e.geometry.location.lng()
      }
      order.latLngOrder = position
      order.center = position
      this.zoom = this.zoom === 15 ? 16 : 15
      console.log(this.orders)
    },
    dragend(e) {
      const position = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng()
      }
      this.setLatLngOrder(position)
    }
  }
}
</script>

<style>
.gmap {
  position: relative;
}
.input-search {
  outline: none;
  padding: 12px 12px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 3px !important;
}
.input-search:focus {
  border: 1px solid #f44336;
}
.input-search::placeholder {
  font-style: 'italic';
  color: rgb(143, 143, 143);
  padding: 10px 2px 10px 2px;
}
.map-icon-label .map-icon {
  font-size: 24px;
  color: #ffffff;
  line-height: 48px;
  text-align: center;
  white-space: nowrap;
}
.label {
  background: #000;
}
</style>
